import React from 'react';
import { getPostProps } from 'src/utils/getPropsForNewsBox';
import { IPostACF } from 'src/services/api/types/IPosts';
import Container from '@components/containers/Container';
import NewsBox from '@components/views/NewsBox';

export interface LatestFaqPostsProps {
  posts: IPostACF[];
}

const LatestFaqPosts: React.FunctionComponent<LatestFaqPostsProps> = (
  props,
) => {
  const { posts = [] } = props;

  return (
    <Container className="grid md:grid-cols-2 lg:grid-cols-4 gap-32">
      {posts
        .map((post) => getPostProps(post))
        .map((post) => (
          <NewsBox
            key={post.ID}
            {...post}
            img={
              post.thumbnail ? post.thumbnail : '/images/post-placeholder.jpg'
            }
          />
        ))}
    </Container>
  );
};

export default LatestFaqPosts;
