import { Tag } from 'src/services/api/types/IPosts';

export const getPostProps = (post: any) => {
  const hasCycle = Object.keys(post?.taxonomies || {})?.includes('cycle');
  const cycle = hasCycle && post.taxonomies.cycle[0].terms;

  const categoryFromPostExtraFields =
    post?.extra_fields?.terms.length > 0
      ? post.extra_fields?.terms[0].name
      : '';

  const categoryFromPost =
    post?.terms && post.terms.length > 0 ? post.terms[0].name : '';

  return {
    ...post,
    img: post?.extra_fields?.thumbnail || post?.thumbnail,
    title: post?.title?.rendered || post.title,
    category: categoryFromPost || categoryFromPostExtraFields,
    tags:
      post?.extra_fields?.tags?.map((tag: Tag) => tag) ||
      post?.tags?.map((tag: Tag) => tag),
    excerpt: post.excerpt?.rendered || post?.excerpt,
    href: post.link,
    cycle,
  };
};
